@media only screen and (min-width: 768px) { 


  /* ---------- Home > Key Findings and Statistics ----------  */

  .kfs-img-1 {
    margin-right: 50px;
  }



  /* ---------- Primary Industries ----------  */

  .pi-div {
    grid-template-columns: auto auto auto;
    width: 75%;
    margin-inline: auto;
  }





  /* ---------- Home > Attending ----------  */

  .home-attending-ul {
    width: 70%;
  }



  
}