@media only screen and (min-width: 1200px) { 


  /* ---------- Primary Industries ----------  */

  .pi-div {
    width: 60%;
  }




  /* ---------- Home > Attending ----------  */

  .home-attending-ul {
    width: 50%;
  }


  
}