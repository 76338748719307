@media only screen and (min-width: 576px) { 



  /* ---------- Home > Key Findings and Statistics ----------  */

  .kfs-img-1 {
    width: 300px;
    margin-right: 50px;
  }

  .kfs-img-2 {
    width: 300px;
    margin-left: 50px;
  }

  .kfs-img-3 {
    width: 300px;
    margin-right: 50px;
  }

  .kfs-img-4 {
    width: 200px;
  }

  .kfs-img-5 {
    margin-right: 25px;
  }

  .kfs-img-6 {
    width: 125px;
    margin-left: 25px;
  }



  /* ---------- Home > Top Industry Trends ----------  */

  .tit-hr {
    width: 80%;
  }






  /* ---------- Primary Industries ----------  */

  .pi-div {
    grid-template-columns: auto auto;
  }



  /* ---------- Home > Attending ----------  */

  .home-attending-ul {
    width: 80%;
  }
  
}