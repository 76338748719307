@media only screen and (min-width: 0px) { 

  /* ---------- Home > Key Findings and Statistics ----------  */

  .kfs-img-1 {
    width: 200px;
  }

  .kfs-text-1, .kfs-text-2, .kfs-text-3, .kfs-text-4, .kfs-text-5, .kfs-text-6 {
    font-size: 20px;
  }

  .kfs-img-2 {
    width: 200px;
  }

  .kfs-img-3 {
    width: 200px;
  }

  .kfs-img-4 {
    width: 250px;
  }

  .kfs-img-5 {
    width: 225px;
  }

  .kfs-img-6 {
    width: 80px;
  }



  .kfs-hr {
    border-color: #4397ca;
  }






  /* ---------- Home > Primary Industries ----------  */

  .pi-div {
    display: grid;
    grid-template-columns: auto;
    justify-content: space-evenly;
    gap: 25px;
  }

  .pi-div-img {
    width: 200px;
    border-radius: 10px;
  }




  /* ---------- Home > Top Industry Trends ----------  */

  .tit-img {
    height: 150px;
  }

  .tit-text-1, .tit-text-2 {
    font-size: 20px;
    white-space: pre-line;
  }

  .tit-hr {
    width: 90%;
  }



  /* ---------- Home > Event Agenda ----------  */

  .eventAgendaTable {
    width: 90%;
  }



  
  /* ---------- Home > Attending ----------  */

  .home-attending-ul li {
    font-size: 20px;
    margin-bottom: 7px;
  }





  /* ---------- Speakers ----------  */

  .speaker-image {
    height: 200px;
    width: 200px;
  }

  .speaker-image-img {
    height: 150px !important;
    width: 150px;
    object-fit: cover;
    object-position: top;
  }

  .speakerName {
    font-size: 23px;
    font-weight: 600;
  } 

  .speakerTitle {
    font-size: 20px;
  }

  .speakerCompany {
    font-size: 20px;
  }

  .speaker-popup-img {
    height: 200px;
    margin-right: 20px;
  }

  .speaker-popup-name {
    font-size: 20px;
    font-weight: 600;
  }

  .speaker-popup-title {
    font-size: 17px;
  }

  .speaker-popup-company {
    font-size: 17px;
  }

  .speaker-popup-bio {
    font-size: 15px;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 10px;
  }







}