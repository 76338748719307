@media only screen and (min-width: 1400px) { 
  
  
  /* ---------- Primary Industries ----------  */

  .pi-div {
    row-gap: 50px;
  }




  /* ---------- Home > Top Industry Trends ----------  */

  .tit-hr {
    width: 60%;
  }




  /* ---------- Home > Event Agenda ----------  */

  .eventAgendaTable {
    width: 60%;
  }




  /* ---------- Home > Attending ----------  */

  .home-attending-ul {
    width: 40%;
  }

  

}