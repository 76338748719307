@media only screen and (min-width: 992px) { 

  /* ---------- Home > Key Findings and Statistics ----------  */

  .ksf-div-2 {
    border-right: 1px solid #4397ca;
  }

  .ksf-div-5 {
    border-right: 1px solid #4397ca;
  }

  .kfs-img-2 {
    margin-right: 25px;
  }

  .kfs-img-3 {
    width: 125px;
    margin-right: 0px;
  }

  .kfs-img-4 {
    width: 250px;
    margin-right: 15px;
  }

  .kfs-img-6 {
    width: 80px;
    margin-right: 0px;
  }





  /* ---------- Primary Industries ----------  */

  .pi-div {
    grid-template-columns: auto auto auto auto;
  }




  /* ---------- Home > Sponsor ----------  */

  .home-sponsor {
    width: 75%;
  }



  /* ---------- Home > Top Industry Trends ----------  */

  .tit-hr {
    width: 70%;
  }


  /* ---------- Home > Event Agenda ----------  */

  .eventAgendaTable {
    width: 80%;
  }



  /* ---------- Speakers ----------  */

  .speaker-image {
    height: 250px;
    width: 250px;
  }

  .speaker-image-img {
    height: 250px !important;
    width: 250px;
  }
  
  .mainSpeakers img
   {
    height: auto !important;
    width: auto !important;
    max-width: 350px;
   }
   .mainSpeakers .speaker-image
   {
    height: auto !important;
    width: auto !important;
   }
   



  /* ---------- Home > Attending ----------  */

  .home-attending-ul {
    width: 60%;
  }


  
}